import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'



export default function Example() {
  return (

      <main>
        <div className="relative overflow-hidden h-screen">

          {/* <Html
      transform
      wrapperClass="htmlScreen"
      distanceFactor={1.17}
      position={[0, 1.56, - 1.4]}
      rotation-x={- 0.256}
    > */}
          <iframe
            className='frame h-screen '
            src="https://ahtishamkianiportfolio.vercel.app/" />
          {/* </Html> */}

        </div>
      </main>

  )
}
